import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FileDropzone, Input, Modal, PhoneInput, phonemasks } from '@forma/forma-ui-kit';
import getValidationSchema from 'data/validationSchema';
import SelectOwnerModal from '../SelectOwnerModal';

import styles from './send-sign-modal.module.css';

interface SendSignModalProps {
  open: boolean,
  onClose: () => void,
  onSubmit: (ownerId: string) => void, // fixme
  isLoading?: boolean
}

const SendSignModal: FC<SendSignModalProps> = ({ open, onClose, onSubmit, isLoading }) => {
  const { t, i18n } = useTranslation();
  const [ selectedOwnerId, setSelectedOwnerId ] = useState<string|null>(null);
  const [ files, setFiles ] = useState<File[]>([]);

  const { register, control, formState: { isValid, errors } } = useForm({
    resolver: yupResolver(getValidationSchema(['name', 'fullName', 'phone']))
  });

  if (!selectedOwnerId) return (
    <SelectOwnerModal
      open={open && !selectedOwnerId}
      onClose={onClose}
      onSubmit={setSelectedOwnerId}
    />
  );

  return (
    <Modal
      title={t('send_to_counteragent_sign')}
      open={open}
      onClose={onClose}
      size="large"
      buttons={[
        {
          children: t('send_to_sign'),
          className: styles.save,
          isLoading: isLoading,
          disabled: !selectedOwnerId || !isValid,
          onClick: () => selectedOwnerId && onSubmit(selectedOwnerId)
        }
      ]}
    >
      <div className={styles.description}>{t('send_to_counteragent_sign_description')}</div>
      <div className={styles.content}>
        <form className={styles.form}>
          <Controller
            control={control}
            name="name"
            render={({ field: { value = '', ...rest } }) => (
              <Input
                containerClass={styles.field}
                placeholder={t('enter_document_name')}
                label={t('document_name')}
                viewStyle="secondary"
                showClearButton
                maxLength={255}
                error={!!errors?.login?.message}
                errorMessage={errors?.login?.message && t('errors.' + errors.login.message)}
                {...rest}
                {...register}
                value={value}
              />
            )}
          />
          <Controller
            control={control}
            name="fullName"
            render={({ field: { value = '', ...rest } }) => (
              <Input
                containerClass={styles.field}
                placeholder={t('placeholders.name')}
                label={t('fullname_short')}
                viewStyle="secondary"
                showClearButton
                maxLength={255}
                error={!!errors?.login?.message}
                errorMessage={errors?.login?.message && t('errors.' + errors.login.message)}
                {...rest}
                {...register}
                value={value}
              />
            )}
          />
          <Controller
            control={control}
            name="phone"
            render={({ field: { value = '', ...rest } }) => (
              <PhoneInput
                masks={phonemasks}
                defaultMaskCode={(i18n.resolvedLanguage === 'en' || !i18n.resolvedLanguage) ? 'US' : i18n.resolvedLanguage.toUpperCase()}
                containerClass={styles.field}
                placeholder={t('placeholders.phone')}
                label={t('fields.phone')}
                viewStyle="secondary"
                showClearButton
                error={!!errors?.login?.message}
                errorMessage={errors?.login?.message && t('errors.' + errors.login.message)}
                autoComplete="phone"
                {...rest}
                {...register}
                value={value}
              />
            )}
          />
        </form>
        <FileDropzone
          placeholder={t('send_to_counteragent_sign_files')}
          accept={{
            'image/jpeg': [], 'image/png': [], 'image/bmp': [], 'image/gif': [], 'image/tiff': [], 'image/webp': []
          }}
          previews={{ columns: 3 }}
          maxFiles={10}
          className={styles.dropzone}
          onChange={setFiles}
        />
      </div>
    </Modal>
  );
};

export default SendSignModal;
